
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";

export default defineComponent({
  name: "Profilim",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const scrollElRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const userController = store.state.ControllersModule.userController;
    const updateUserModel = ref<UpdateUserModel>({});
    const phone = ref<string>("");

    //Create form validation object
    const infoForm = Yup.object().shape({
      name: Yup.string().required(t("validators_error.required")).label("Name"),
      surname: Yup.string()
        .required(t("validators_error.required"))
        .label("Surname"),
      email: Yup.string()
        .required(t("validators_error.required"))
        .email(t("validators_error.email"))
        .label("Email"),
    });

    const getUserInfo = () => {
      isLoading.value = true;

      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {
            updateUserModel.value.name = response.getValue().name;
            updateUserModel.value.surname = response.getValue().surname;
            updateUserModel.value.email = response.getValue().email;
            updateUserModel.value.countryCode = response.getValue().countryCode;
            phone.value = response.getValue().phoneNumber;
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    //Form submit function
    const onSubmit = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      userController
        .updateUser(updateUserModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    onMounted(() => {
      getUserInfo();
      setCurrentPageTitle(t("profile.title"));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    return {
      infoForm,
      PAGE_TITLE_PATH,
      translate,
      onSubmit,
      updateUserModel,
      phone,
      isLoading,
    };
  },
});
